<template>
  <basic-container>
    <el-container>
      <CommonTree
        style="flex-shrink: 0"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :popover-show="false"
        :expandOnClickNode="false"
        treeTitle="角色分组"
        @include-down="includeDown"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout :head-btn-options="headBtnOptions"
                     :head-title="$t('cip.plat.authority.role.title.indexHeadTitle')"
                     @head-add="getNew"
                     @head-romve="headRomve">
        </head-layout>
        <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                          @grid-head-search="searchChange" @grid-head-empty="searchReset">
        </grid-head-layout>
        <grid-layout ref="gridLayOut"
                     :tableOptions="option"
                     :tableData="data"
                     :table-loading="loading"
                     :data-total="page.total"
                     :page="page"
                     @gird-handle-select-click="selectionChange"
                     @page-size-change="onLoad"
                     @page-current-change="onLoad"
                     @page-refresh-change="onLoad"
                     :gridRowBtn="gridRowBtn"
                     @grid-romve="rowDel"></grid-layout>
      </el-main>
    </el-container>
    <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
               style="margin-top: 50px;" :show-close="false">
      <template slot="title">
        <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title="" icon="">
        </dialog-head-btn>
      </template>
      <form-layout v-if="isShow" :column="option.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-drawer>
      <CommonDialog
        v-if="showRoleDialog"
        :showFullscreen="true"
        :dialogTitle="$t('cip.plat.authority.role.title.indexHeadTitle')"
        width="60%"
        @cancel="showRoleDialog = false"
        @confirm="treeNodeSave"
      >
      <role ref="role" :classification="classification" :id-list="idList" :node-id="nodeId"></role>
    </CommonDialog>
    <el-dialog
      title="角色类型管理"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      top="5vh"
      :fullscreenByTree="fullscreenByTree"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form
        :option="treeDialogOption"
        ref="addForm"
        v-model="treeForm"
        @submit="roleTypeTreeNodeSave"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="showTreeDialog = false">{{
            $t(`cip.cmn.btn.celBtn`)
          }}</el-button>
        <el-button size="small" type="primary" @click="handleTreeNodeSave">{{
            $t(`cip.cmn.btn.defBtn`)
          }}</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  add, classificationRoleGetList,
  classificationRoleSubmit,
  classificationRoleRemove,
  getRoleTreeById,
  grant,
  getRoleTreeDicData,
  saveRoleTypeTree,
  getRoleTypeTree,
  removeRoleTypeTree,
  saveRoleType, roleTypeRemove, getAllByTypeId
} from "@/api/system/role";
import role from "@/views/authority/roleComponents";
import {getSysConfig} from "@/api/system/user";
import {mapGetters} from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import CommonTree from "@/views/components/com_tree/index.vue";
import ServerNameEnum from "@/util/ServerNameEnum";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
    role,
    CommonDialog
  },
  data() {
    return {
      searchTitle: "typeName",
      defaultProps: {
        label: "typeName",
        value: "typeCode",
        key: "typeCode",
      },
      treeData: [],
      nodeId: '',
      idList: ["1123598816738675201"],
      node: {},
      treeForm: {},
      showTreeDialog: false,
      fullscreenByTree: false,
      fullscreen: false,
      classification: "classification",
      orgObj: {},
      isShow: false,
      dataObj: '',
      dataTotal: '',
      tenantStatus: "",
      searchForm: {},
      showRoleDialog: false,
      searchColumns: [
        {
          label: "",
          prop: "roleName",
          type: "input",
          span: 4,
          search: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleName"),
        },
        // {
        //   label: "",
        //   prop: "tenantId",
        //   type: "select",
        //   span:4,
        //   placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
        //   dicUrl: "/api/sinoma-system/tenant/select",
        //   addDisplay: false,
        //   editDisplay: false,
        //   viewDisplay: website.tenantMode,
        //   props: {
        //     label: "tenantName",
        //     value: "tenantId"
        //   },
        //   hide: !website.tenantMode,
        //   search: website.tenantMode,
        //
        // },
        {
          label: "",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
          prop: "roleAlias",
          type: "input",
          span: 4,
          search: true,
        },
      ],
      form: {},
      box: false,
      props: {
        label: "title",
        value: "key"
      },
      webMenuGrantList: [],
      appMenuGrantList: [],
      dataScopeGrantList: [],
      apiScopeGrantList: [],
      apiGrantList: [],
      menuTreeObj: [],
      dataScopeTreeObj: [],
      apiScopeTreeObj: [],
      selectionList: [],
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        menuWidth: 300,
        index: true,
        selection: true,
        selectable: (row) => {
          return row.id != "1123598816738675201";
        },
        viewBtn: false,
        dialogWidth: 900,
        dialogClickModal: false,
        column: [
          {
            label: this.$t("cip.plat.authority.role.field.roleName"),
            prop: "roleName",
            search: true,
            align: 'left',
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleName"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.authority.role.field.tenantId"),
            prop: "tenantId",
            type: "select",
            dicUrl: "/api/sinoma-system/tenant/select",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: website.tenantMode,
            align: 'left',
            overHidden: true,
            props: {
              label: "tenantName",
              value: "tenantId"
            },
            hide: !website.tenantMode,
            search: website.tenantMode,
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
              trigger: "click"
            }]
          },
          {
            label: this.$t("cip.plat.authority.role.field.roleAlias"),
            prop: "roleAlias",
            search: true,
            align: 'left',
            overHidden: true,
            width: 600,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.authority.role.field.roleType"),
            type: "select",
            align: 'center',
            hide: true,
            overHidden: true,
            width: 300,
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            prop: "roleType",
          },
          {
            label: this.$t("cip.plat.authority.role.field.parentId"),
            prop: "parentId",
            dicData: [],
            type: "tree",
            hide: true,
            overHidden: true,
            width: 300,
            props: {
              label: "title"
            },
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.parentId"),
                trigger: "click"
              }
            ]
          }
        ]
      },
      data: []
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.role_add, false),
        viewBtn: this.vaildData(this.permission.role_view, false),
        delBtn: this.vaildData(this.permission.role_delete, false),
        editBtn: this.vaildData(this.permission.role_edit, false)
      };
    },
    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "类型名称",
            prop: "typeName",
            placeholder: "请输入角色类型名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入角色类型名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "类型编码",
            prop: "typeCode",
            maxlength: 20,
            placeholder: "请输入角色类型编码",
            rules: [
              {
                required: true,
                message: "请输入角色类型编码",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "上级类型",
            prop: "parentId",
            type: "tree",
            props: {
              label: "typeName",
              value: "id",
              key: "parentId",
            },
            placeholder: "上级类型",
            dicUrl: `${ServerNameEnum.SERVER_SYSTEM_}/roleTypeTree/tree`,
          },
          {
            label: "描述",
            prop: "remark",
            maxlength: 20,
            placeholder: "请输入描述",
          },
        ],
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permission.role_add) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.role_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permission.role_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "grid-romve",
            type: "text",
            icon: ""
          })
      }
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);

      });
      return ids.join(",");
    },
    idsArray() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids;
    }
  },
  created() {
    this.getSysConfig()
    this.onLoad(this.page)
    this.initData()
    this.initTree()
  },
  mounted() {
    this.onLoad(this.page)
    this.initData()
    this.initTree()
  },
  methods: {
    // 新增左侧树节点
    roleTypeTreeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          saveRoleTypeTree(this.treeForm)
            .then((res) => {
              const {msg, data} = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    fullClick() {
      this.fullscreenByTree = !this.fullscreenByTree;
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    // 左侧树新增
    treeMenuAdd() {
      this.treeForm = {};
      this.treeReadOnlyFlag = false;
      this.fullscreenByTree = false;
      this.showTreeDialog = true;
    },
    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.treeForm = data;
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeRoleTypeTree(data.id).then((res) => {
            if (res.data.data) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.initTree();
            } else {
              this.$message.warning("删除失败，当前角色类型下有角色数据！")
            }
          });
        })
        .catch(() => {
        });
    },
    // 点击左侧树节点
    treeNodeClick(item) {
      this.idList = ["1123598816738675201"];
      this.nodeId = item.id;
      getAllByTypeId(item.id).then((res) => {
        res.data.data.forEach((e) => {
          this.idList.push(e.roleId)
        })
      }).then(() => {
        this.onLoad(this.page, {
          typeId: item.id
        })
      })
    },
    initTree() {
      getRoleTypeTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoad(this.page, {});
        })
        .catch((err) => {
          this.treeLoading = true;
        });

      /*      getRoleTreeDicData("role_tree_data").then((res) => {
              let dicData = res.data.data
              dicData.forEach((e) => {
                let defaultProps = {
                  title: e.dictValue,
                  key: e.dictKey,
                  value: e.dictValue
                }
                this.treeData.push(defaultProps)
              })
            });*/
    },
    treeNodeSave() {
      let ids = [];
      this.$refs.role.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      this.$loading();
      saveRoleType(ids.toString(), this.nodeId).then((res) => {
        if (res.data.code == 200) {
          // classificationRoleSubmit(ids.toString()).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.$loading().close();
          // });
        }
      })
      this.showRoleDialog = false;
    },
    getSysConfig() {
      getSysConfig().then(res => {
        let data = res.data.data
        this.tenantStatus = data.is_tenant;
      })
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },

    getNew() {
      console.log(this.nodeId, "this.nodeId")
      console.log(this.idList, "this.idList")
      if (this.nodeId) {
        this.showRoleDialog = true
      } else {
        this.$message.warning("请先选择左侧类型树节点！");
      }
    },
    headRomve() {
      this.handleDelete()
    },
    handleDelete() {
      if (this.nodeId) {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return roleTypeRemove(this.ids, this.nodeId);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.$refs.gridLayOut.selectionClear();
          });
      } else {
        this.$message.warning("请先选择左侧类型树节点！");
      }
    },
    initData(roleId) {
      getRoleTreeById(roleId).then(res => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    submit() {
      const menuList = [...this.$refs.webTreeMenu.getCheckedKeys(), ...this.$refs.webTreeMenu.getHalfCheckedKeys()];
      const appMenuList = [...this.$refs.appTreeMenu.getCheckedKeys(), ...this.$refs.appTreeMenu.getHalfCheckedKeys()];
      appMenuList.forEach(a => {
        menuList.push(a)
      })
      const dataScopeList = this.$refs.treeDataScope.getCheckedKeys();
      const apiScopeList = this.$refs.treeApiScope.getCheckedKeys();
      grant(this.idsArray, menuList, dataScopeList, apiScopeList).then(() => {
        this.box = false;
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        this.onLoad(this.page);
      });
    },
    rowSave(row, done, loading) {
      add(row).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },

    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return roleTypeRemove(row.id, this.nodeId);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.roleName == '') {
        delete params.roleName
      }
      if (params.roleAlias == '') {
        delete params.roleAlias
      }
      delete params.$tenantId
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridLayOut.selectionClear();
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData(this.form.id);
      }
      // done();
    },
    checked(id, data, newArr) {
      data.forEach(item => {
        if (item.id == id) {
          if (!item.hasChildren) {
            newArr.push(item.id);
          }
        } else {
          if (item.hasChildren && item.children.length != 0) {
            this.checked(id, item.children, newArr);
          }
        }
      });
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      if (this.nodeId) {
        params = {
          typeId: this.nodeId
        }
      }
      this.page = page
      this.loading = true;
      console.log(page)
      classificationRoleGetList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.data = data.records;
        this.$refs.gridLayOut.page.total = data.total;
        this.loading = false;
        this.selectionClear();
      });
    }
  }
};
</script>
<style scoped>
.qmDialog ::v-deep .el-dialog__body {
  padding: 10px !important;
}

.qmDialog ::v-deep .el-table {
  height: calc(100vh - 360px) !important;
  max-height: calc(100vh - 360px) !important;
}
</style>
