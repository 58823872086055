var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.plat.authority.role.title.indexHeadTitle"),
        },
        on: { "head-add": _vm.getNew, "head-romve": _vm.headRomve },
      }),
      _c("grid-head-layout", {
        ref: "searchFrom",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        staticClass: "roleComponents",
        attrs: {
          tableOptions: _vm.option,
          tableData: _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          page: _vm.page,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-edit": _vm.rowUpdate,
          "grid-romve": _vm.rowDel,
          "head-permissions": _vm.handleRole,
          selectUserList: _vm.selectUserList,
        },
      }),
      _c(
        "el-drawer",
        {
          staticClass: "el-drawer__wrapper avue-dialog avue-crud__dialog",
          staticStyle: { "margin-top": "50px" },
          attrs: { visible: _vm.isShow, size: "70%", "show-close": false },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("dialog-head-btn", {
                attrs: { "head-title": "", icon: "" },
                on: {
                  "dialog-head-save-click": _vm.save,
                  "dialog-head-cancel-click": _vm.cancel,
                },
              }),
            ],
            1
          ),
          _vm.isShow
            ? _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.option.column, dataForm: _vm.dataObj },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.box
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.plat.authority.role.field.rolePermissions"),
                "append-to-body": "",
                visible: _vm.box,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.box = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.plat.authority.role.field.webMenumissions"
                        ),
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "webTreeMenu",
                        attrs: {
                          data: _vm.webMenuGrantList,
                          "show-checkbox": true,
                          "node-key": "id",
                          "default-checked-keys": _vm.menuTreeObj,
                          "default-expanded-keys": _vm.menuTreeObj,
                          props: _vm.props,
                        },
                        on: { "check-change": _vm.handleCheckChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.plat.authority.role.field.appMenumissions"
                        ),
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "appTreeMenu",
                        attrs: {
                          data: _vm.appMenuGrantList,
                          "show-checkbox": "",
                          "node-key": "id",
                          "default-checked-keys": _vm.menuTreeObj,
                          "default-expanded-keys": _vm.menuTreeObj,
                          props: _vm.props,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.plat.authority.role.field.dataPermissions"
                        ),
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "treeDataScope",
                        attrs: {
                          data: _vm.dataScopeGrantList,
                          "show-checkbox": "",
                          "node-key": "id",
                          "default-checked-keys": _vm.dataScopeTreeObj,
                          "default-expanded-keys": _vm.dataScopeTreeObj,
                          props: _vm.props,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.plat.authority.role.field.protPermissions"
                        ),
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "treeApiScope",
                        attrs: {
                          data: _vm.apiScopeGrantList,
                          "show-checkbox": "",
                          "node-key": "id",
                          "default-checked-keys": _vm.apiScopeTreeObj,
                          "default-expanded-keys": _vm.apiScopeTreeObj,
                          props: _vm.props,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.box = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showUserDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("userinfo.selectionUserInfo"),
                visible: _vm.showUserDialog,
                width: "70vw",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showUserDialog = $event
                },
              },
            },
            [
              _c("role-user-dialog", {
                on: {
                  submitUserList: _vm.submitUserList,
                  cancelUserList: _vm.cancelUserList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.plat.sys.user.field.roleConfig"),
            "append-to-body": "",
            visible: _vm.roleBox,
            width: "90%",
            height: _vm.dialogHeight,
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleBox = $event
            },
          },
        },
        [
          _vm.roleBox
            ? _c("departmentPersonnel", {
                ref: "departmentPersonnel",
                attrs: { currentRole: _vm.currentRole },
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleBox = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }